import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { JSONEditor } from '@json-editor/json-editor';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import FieldWrapper from 'components/FieldWrapper';

const JsonEditorComponent = ({ label, name, value, errorMessage }) => {
  const editorRef = useRef(null);
  const editorContainerRef = useRef(null);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (!editorContainerRef.current) return;
    // Initializing JSONEditor
    const editor = new JSONEditor(editorContainerRef.current, {
      mode: 'form',
      modes: ['tree', 'code', 'form'],
      schema: { type: 'object', title: label },
      startval: value || {},
      theme: 'bootstrap5',
      iconlib: 'fontawesome5',
    });

    editorRef.current = editor;

    editor.on('change', () => {
      // Preventing the first change event from firing on mount
      if (isFirstMount.current) {
        isFirstMount.current = false;
        return;
      }
      const updatedValue = editor.getValue();
      setFieldValue(name, updatedValue);
      setFieldTouched(name, true);
    });

    // Clearing the input field after adding a new property in the JSON editor but only for the first level properties
    editor.on('add', () => {
      setTimeout(() => {
        const inputField = editorContainerRef.current.querySelector(
          '#root-property-selector'
        );
        if (inputField) {
          inputField.value = '';
        }
      }, 100);
    });

    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [label, name]);

  return (
    <FieldWrapper>
      <div ref={ editorContainerRef } />
      { !!errorMessage && <span className="hasErrors">{ errorMessage }</span> }
    </FieldWrapper>
  );
};

JsonEditorComponent.propTypes = {
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.shape({}),
};

export default JsonEditorComponent;
