import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EditFeatureFlag from './Edit';
import FeatureFlagsListView from './List';
import CreateFeatureFlag from './Create';
import withEntityPermissions from 'lib/hooks/withEntityPermissions';
import { ViewMode } from 'lib/enums';

export const FeatureFlag = () => {
  return (
    <Switch>
      <Route
        path="/dev/featureFlag"
        exact={ true }
        component={ withEntityPermissions(FeatureFlagsListView, ViewMode.READ) }
      />
      <Route
        path="/dev/featureFlag/edit/:handle"
        exact={ true }
        component={ withEntityPermissions(EditFeatureFlag, ViewMode.EDIT) }
      />
      <Route
        path="/dev/featureFlag/create"
        exact={ true }
        component={ withEntityPermissions(CreateFeatureFlag, ViewMode.CREATE) }
      />
      <Route
        component={ withEntityPermissions(FeatureFlagsListView, ViewMode.READ) }
      />
    </Switch>
  );
};
export default FeatureFlag;
