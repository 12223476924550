import { EntityType, ViewMode } from 'lib/enums';
import { map, pick } from 'ramda';

export const columns = [
  {
    field: 'description',
    headerName: 'Name',
    flex: 1,
    minWidth: 300,
  },
  {
    field: 'handle',
    headerName: 'Initiative field handle',
    flex: 1,
    minWidth: 300,
  },
];

export const actions = (initiativeType, id) => [
  {
    key: 'id',
    url: `/initiative/${ initiativeType }/${ id }/fields/`,
    label: 'edit',
    action: ViewMode.EDIT,
    entity: EntityType.INITIATIVE,
  },
];

export const getRows = (configFields = []) => {
  const fields = configFields.map((row, index) => ({ ...row, id: index }));
  return map(pick(['id', 'handle', 'description']), fields);
};
