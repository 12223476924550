/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Label } from '@nike/eds';
import styles from './styles.module.scss';
import config from 'conf';

const webOrigin = config.WEB_PREVIEW_ORIGIN;

const useSurveyPreviewReady = () => {
  // Even if we know the iframe has loaded, the web may not have
  // rendered the component that needs to receive the data yet.
  // The web sends a message to the window.parent when it's ready
  // to receive the survey data.
  const [data, setData] = useState(null);
  useEffect(() => {
    const messageListener = (event) => {
      if (
        event.origin === webOrigin &&
        event.data?.id === 'admin-console-survey-preview-ready'
      ) {
        setData(true);
      }
    };
    window.addEventListener('message', messageListener, false);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);
  return data;
};

const useSurveyPreview = (surveyProps, ready) => {
  // Uses a callback ref so we know when the iframe has loaded.
  // iframe.onLoad does not work.
  const [iRef, setIRef] = useState(null);
  const refFn = useCallback(setIRef, []);
  useEffect(() => {
    if (ready && iRef && surveyProps) {
      setTimeout(() => {
        iRef.contentWindow.postMessage({ id: 'admin-console-survey-preview', survey: surveyProps }, webOrigin);
      }, 100);
    }
  }, [surveyProps, iRef, ready]);
  return refFn;
};

const SurveyPreview = ({
  surveyProps,
}) => {
  const ready = useSurveyPreviewReady();
  const refFn = useSurveyPreview(surveyProps, ready);

  return (
    <div className={ styles.surveyPreview }>
      <Label font="title-3" className={ styles.title }>
        Survey Preview
      </Label>
      <iframe
        ref={ refFn }
        title="Survey Preview"
        src={ `${ webOrigin }/webview/survey-preview` }
        width="100%"
        height="100%" />
    </div>
  );
};

export default SurveyPreview;
