import React, { useCallback, useEffect } from 'react';
import { FormContainer } from 'components/FormContainer';
import FeatureFlagForm from 'components/forms/FeatureFlag';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styles from '../../ConfigEdit/Edit/styles.module.scss';
import {
  useFetchFeatureFlagsByHandleQuery,
  usePatchFeatureFlagMutation,
} from 'features/adminApi/endpoints/featureFlag';
import { Text } from '@nike/eds';
import Loading from 'components/Loading';

const EditFeatureFlag = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { handle } = useParams();
  const { data: featureFlagData, isFetching } =
    useFetchFeatureFlagsByHandleQuery(handle);
  const [usePatchFeatureFlag, patchFeatureFlagResult] =
    usePatchFeatureFlagMutation();

  const handleFormSubmit = useCallback(async (values) => {
    usePatchFeatureFlag({
      handle: values.handle,
      activeItems: values.value.activeItems,
    });
  }, []);

  useEffect(() => {
    if (patchFeatureFlagResult.isSuccess) {
      addToast('Feature Flag Data Saved successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      history.push('/dev/featureFlag');
    }
  }, [history, patchFeatureFlagResult.isSuccess]);

  useEffect(() => {
    if (patchFeatureFlagResult?.error?.data?.errorMessage) {
      addToast(patchFeatureFlagResult?.error?.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [patchFeatureFlagResult?.error?.data?.errorMessage]);

  return isFetching ? (
    <Loading text="Fetching feature flag data" />
  ) : (
    <>
      <FormContainer className={ styles.assetContainer }>
        <div className={ styles.title }>
          <Text as="h1" font="title-1">
            Edit Feature Flag
          </Text>
        </div>
        { featureFlagData && (
          <FeatureFlagForm
            onSubmit={ handleFormSubmit }
            initialValues={ featureFlagData }
          />
        ) }
      </FormContainer>
    </>
  );
};

export default EditFeatureFlag;
