import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { FormContainer } from 'components/FormContainer';
import { ViewMode } from 'lib/enums';
import styles from '../../ConfigEdit/Edit/styles.module.scss';
import FeatureFlagForm from 'components/forms/FeatureFlag';
import { Text } from '@nike/eds';
import { usePostFeatureFlagMutation } from 'features/adminApi/endpoints/featureFlag';
import { featureFlagPrefix } from 'components/forms/utils';

const CreateFeatureFlag = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [usePostFeatureFlag, postFeatureFlagResult] =
    usePostFeatureFlagMutation();

  const handleFormSubmit = useCallback((values) => {
    usePostFeatureFlag({
      handle: `${ featureFlagPrefix }-${ values.handle }`,
      activeItems: values.value.activeItems,
    });
  }, []);

  useEffect(() => {
    if (postFeatureFlagResult?.error?.data?.errorMessage) {
      addToast(postFeatureFlagResult.error.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [postFeatureFlagResult?.error?.data?.errorMessage]);

  useEffect(() => {
    if (postFeatureFlagResult.isSuccess) {
      addToast('Feature Flag created successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      history.push('/dev/featureFlag');
    }
  }, [postFeatureFlagResult.isSuccess]);

  return (
    <>
      <FormContainer className={ styles.assetContainer }>
        <div className={ styles.title }>
          <Text as="h1" font="title-1">
            Create Feature Flag
          </Text>
        </div>
        <FeatureFlagForm
          viewMode={ ViewMode.CREATE }
          onSubmit={ handleFormSubmit }
          initialValues={{}}
        />
      </FormContainer>
    </>
  );
};

export default CreateFeatureFlag;
