import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'components/DataTable';
import Loading from 'components/Loading';
import { useToasts } from 'react-toast-notifications';
import SearchBar from 'views/DevView/SearchBar';
import {
  useFetchConfigFieldsQuery,
  useFetchConfigFieldQuery,
} from 'features/adminApi/endpoints/fields';
import { useParams, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { actions, columns, getRows } from './utils';

// eslint-disable-next-line max-statements
const ConfigFieldsListView = () => {
  const history = useHistory();
  const { initiativeType, id } = useParams();
  const { addToast } = useToasts();
  const [searchValue, setSearchValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, isFetching, isError, error } = useFetchConfigFieldsQuery(id);
  const configFields = useMemo(
    () => getRows(data?.initiativeFields),
    [data?.initiativeFields]
  );
  const [filteredRows, setFilteredRows] = useState([]);

  const {
    data: configField,
    isFetching: isConfigFieldFetching,
    isError: isConfigFieldError,
  } = useFetchConfigFieldQuery(
    { initiativeHandle: id, fieldHandle: searchValue },
    {
      skip: !isSubmitting || !searchValue,
    }
  );

  useEffect(() => {
    if (isEmpty(searchValue)) {
      setFilteredRows(configFields);
    }
    if (!isEmpty(searchValue) && isSubmitting) {
      const filteredRow = filteredRows.filter(
        (row) => row.handle.toLowerCase() === searchValue.toLowerCase()
      );
      if (filteredRow.length) setFilteredRows(filteredRow);
      if (!isConfigFieldFetching) {
        setIsSubmitting((prevState) => !prevState);
        if (!isConfigFieldError && !isEmpty(configField)) {
          setFilteredRows(getRows([configField]));
        } else {
          addToast('Config Field not found', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    }
  }, [
    configFields,
    searchValue,
    isSubmitting,
    configField,
    isConfigFieldError,
    isConfigFieldFetching,
  ]);

  useEffect(() => {
    if (isError && !isFetching) {
      addToast(`${ error?.error }`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [isError, isFetching, error]);

  const handleRowClick = useCallback(
    ({ colDef: { field }, row: { handle } }) => {
      if (field !== 'actions' && handle) {
        history.push(`/initiative/${ initiativeType }/${ id }/fields/${ handle }`);
      }
    },
    [history]
  );

  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value.trim());
  }, []);

  const handleSearchSubmit = useCallback(() => {
    setIsSubmitting((prevState) => !prevState);
  }, []);

  return (
    <div data-testid="configuration-fields-container">
      { isFetching ? (
        <Loading text="Fetching configuration fields" />
      ) : (
        <div>
          <SearchBar
            searchValue={ searchValue }
            handleSearchChange={ handleSearchChange }
            handleSearchSubmit={ handleSearchSubmit }
            disabled={ !searchValue.length }
            isFetching={ isConfigFieldFetching }
          />
          <DataTable
            rows={ filteredRows }
            columns={ columns }
            actions={ actions(initiativeType, id) }
            heading={ `Configuration Fields for initiative: ${ id }` }
            handleCellClick={ handleRowClick }
            createHref={ `/initiative/${ initiativeType }/${ id }/fields/create` }
            createLabel="add"
          />
        </div>
      ) }
    </div>
  );
};

export default ConfigFieldsListView;
