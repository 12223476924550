/* eslint-disable max-statements */
/* eslint-disable no-process-env */
// modules
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFetchinitiativeListQuery } from 'features/adminApi/endpoints/initiative';
import {
  applyTo,
  compose,
  path,
  pipe,
  pathOr,
  propOr,
  map,
  toLower,
  split,
} from 'ramda';
import { useOktaAuth } from '@okta/okta-react';
import NoAccess from 'components/NoAccess';
import { toLowerKeys } from 'lib/object';
import jwt_decode from 'jwt-decode'; // eslint-disable-line
import { permissionsByRole } from 'lib/auth';
import config from 'conf';
import { PermissionGroups } from 'lib/enums';

// aliased
import { propTypes } from 'lib/react';
import { InitiativeTypes } from 'utils';
const jwtDecode = jwt_decode; // eslint-disable-line
const getGroupsFromToken =
  jwtDecode.length > 0 ? pipe(jwt_decode, pathOr([], ['groups'])) : [];

const defaultOrganization = PermissionGroups.DEFAULT_ORG.toLowerCase();
const defaultAll = PermissionGroups.DEFAULT_ALL.toLowerCase();

export const convertEnv = (env) => {
  switch (toLower(env)) {
    case 'local':
      return 'development';
    case 'dev':
      return 'development';
    case 'staging':
      return 'development';
    case 'prod':
      return 'production';
    default:
      return env;
  }
};

const parseGroup = pipe(toLower, split('.'), (arr) => {
  const env = convertEnv(path([2], arr));
  const organization = path([3], arr);
  const role = path([5], arr);
  const initiative = path([4], arr);

  //Note: Business rule that if org = JTL & init = ALL
  //We will give organization = ALL permissions
  const overrideOrganization =
    organization === defaultOrganization && initiative === defaultAll
      ? defaultAll
      : organization;

  return {
    env,
    organization: overrideOrganization,
    initiative,
    role,
  };
});

const getEnvironment = (groupPermissions) => {
  const infraNodeEnv = config.env;
  // eslint-disable-next-line no-undef
  console.log('RBAC-process.env.NODE_ENV', process.env.NODE_ENV);
  console.log('RBAC-process.env.infraNodeEnv', infraNodeEnv);
  console.log('RBAC-groupPermissions', groupPermissions);
  console.log('RBAC-converproc', convertEnv(infraNodeEnv));
  return groupPermissions.filter((group) => {
    console.log('RBAC-group.env', group.env);
    return group.env === convertEnv(infraNodeEnv);
  });
};

export const GroupsContext = React.createContext(null);

export const userHasEnvAccess = (groupPermissions) => {
  const infraNodeEnv = config.env;
  return groupPermissions.some(
    (group) => group.env === convertEnv(infraNodeEnv)
  );
};

export default applyTo(
  ({ children }) => {
    const [groups, setGroups] = useState([]);

    const { authState } = useOktaAuth();

    useEffect(() => {
      const accessToken = path(['accessToken', 'accessToken'], authState);
      const gs = accessToken && getGroupsFromToken(accessToken);
      const groupsByEnvParsed = gs && getEnvironment(map(parseGroup, gs));

      setGroups(groupsByEnvParsed);

      //   //TEMP GROUPS - ENABLE FOR TESTING:
      //   const tempGroups = [
      //     'App.JTL.Dev.NSRL.ALL.PowerUser',
      //     //'App.JTL.Dev.NSRL.watch-your-activity.PowerUser',
      //     //'App.JTL.Dev.JTL.ALL.PowerUser',
      //     // 'App.JTL.Prod.VL.0000.Viewer',
      //     //'App.JTL.Prod.VL.MNTM.Viewer',
      //     //'App.JTL.Dev.VL.MNTM.PowerUser',
      //     //'App.JTL.Dev.JTL.WYA.SuperAdmin',
      //     //'App.JTL.Dev.NSRL.ALL.Viewer',
      //     //'App.JTL.Dev.JTL.digital-service-athlete-digital-services.PowerUser',
      //     //'App.JTL.Dev.JTL.athlete-digital-services.PowerUser',
      //     //   'App.JTL.Dev.JTL.ALL.PowerUser',
      //     //'App.JTL.Dev.JTL.ALL.Viewer',
      //     //'App.JTL.Dev.JTL.ALL.SuperAdmin',
      //     //   'App.JTL.Dev.VL.ALL.PowerUser',
      //   ];
      //   const groupsByEnvParsedTest = getEnvironment(map(parseGroup, tempGroups));
      //   setGroups(groupsByEnvParsedTest);
      //   //END TEMP
    }, [authState, setGroups]);

    //User has no groups or no access for this env
    //if (!groups || (groups && groups.length === 0)) {
    if (groups && groups.length === 0) {
      return <NoAccess />;
    }

    return (
      <GroupsContext.Provider value={ groups }>{ children }</GroupsContext.Provider>
    );
  },
  pipe(
    propTypes({
      children: PropTypes.node,
    }),
    memo
  )
);

//returns true if any access (CREATE,EDIT,READ,DELETE) entity access is present user's role
export const userEntityPermissions = (entity, mainLayoutRoute = false) => {
  const groups = useContext(GroupsContext);
  const { isFetching, data } = permissionsByRole();

  const hasAnyPermissionsPerEntity = useMemo(() => {
    const permissions = toLowerKeys(data);
    const entityType = InitiativeTypes.includes(entity) ? 'initiative' : entity;

    return (
      groups &&
      groups.some((access) => {
        const entityGroup = propOr(null, access.role, permissions);
        const permissionSet =
          entityGroup && propOr(null, entityType, entityGroup);
        return permissionSet && permissionSet.length > 0;
      })
    );
  }, [isFetching, data]);

  //Handles access-denied issues on initiative and related pages while checking for operator-role.
  return mainLayoutRoute
    ? {
      isLoading: isFetching,
      hasAnyPermissionsPerEntity,
    }
    : hasAnyPermissionsPerEntity;
};

//returns entity/init specific access list [CREATE,EDIT,READ,DELETE]
//uses most restrictive access list if user has multiple access
//if no entity passed, uses "initiative"
export const userInitiativePermissions = (
  initiativeHandle,
  entity = 'initiative'
) => {
  const groups = useContext(GroupsContext);

  //TODO - this is not necessary to load this permissions object over and over
  const { isFetching, data } = permissionsByRole();

  const permissions = useMemo(() => {
    return toLowerKeys(data);
  }, [isFetching, data]);

  const initDetails = (id) => {
    const defaultOrganization = 'JTL';
    const { data, isLoading } = useFetchinitiativeListQuery(undefined, {
      selectFromResult: ({ data, error, isLoading }) => ({
        data: data?.find((item) => item.handle === id),
        isLoading,
        error,
      }),
      skip: !id,
    });

    const organization = useMemo(() => {
      return propOr(defaultOrganization, 'organization', data);
    }, [data, isLoading]);

    const shortHandle = useMemo(() => {
      return propOr('', 'shortHandle', data);
    }, [data, isLoading]);

    return { organization, shortHandle };
  };

  const { shortHandle, organization } = initDetails(initiativeHandle);

  //user has access to init if org=ALL, org matches & init=ALL, or init matches
  const accessToAllOrgs = (grp) =>
    grp.organization === defaultAll.toLowerCase();

  //user has ALL access within an org or specific inits within org
  const accessToAllInitsInOrg = (grp) =>
    organization.includes(grp.organization && grp.organization.toUpperCase()) &&
    (grp.initiative === defaultAll.toLowerCase() ||
      grp.initiative === grp.organization);

  //Access to init provided if an init is in create mode or matches RBAC
  const accessToInit = (grp) =>
    (shortHandle ? grp.initiative === shortHandle : true);

  //Sort and return lowest level access
  const lowestAccessRole = useMemo(() => {
    const userRole =
      groups &&
      groups
        .filter(
          (grp) =>
            accessToAllOrgs(grp) ||
            accessToAllInitsInOrg(grp) ||
            accessToInit(grp)
        )
        .map((itm) => itm.role);
    return sortByPermission(userRole, 'desc');
  }, [groups, shortHandle, organization]);

  const userAccess = useMemo(() => {
    const access = compose(
      propOr([], entity),
      propOr([], lowestAccessRole)
    )(permissions);
    console.log('RBAC-lowestAccessRole', lowestAccessRole);
    console.log('RBAC-userAccess', access);
    return access;
  }, [permissions, entity, lowestAccessRole]);

  return userAccess;
};

export function sortByPermission(roles, direction) {
  const orderBy = {
    asc: ['superadmin', 'poweruser', 'viewer'],
    desc: ['viewer', 'poweruser', 'superadmin'],
  };
  const ordering = {},
        sortOrder = orderBy[direction];
  for (let i = 0; i < sortOrder.length; i++) {
    ordering[sortOrder[i]] = i;
  }
  const sorted =
    roles &&
    roles.sort((first, last) => {
      return ordering[first] - ordering[last];
    });
  console.log('RBAC-Roles for this user sorted', sorted);
  return propOr([], 0)(sorted);
}

//TODO
export const userHasPermissions = (initiativeHandle, entity) => {
  //const permissionGroups = useContext(GroupsContext);
  //3) if no initiativeHandle, check is init-agnostic
  //get permissions for this entity only
  //userEntityPermissions(entity);

  //(2) if no entity, check is entity-agnostic
  //userInitiativePermissions

  //else
  //1) get permissions for this init & entity combined

  //What access does this user have to this init>entity
  //return map(parseGroup, permissionGroups);
  //TEMP
  return ['CREATE', 'EDIT', 'READ', 'DELETE'];
};
