/* eslint-disable react/no-multi-comp */
import { Button } from '@nike/eds';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import React, { useCallback } from 'react';

const PreviewInANewWinBtn = ({
  handleSave,
  layoutType,
  disabled = false,
  variant = 'primary',
  text = 'Preview in a new window',
}) => {
  const handleClick = useCallback(() =>
    handleSave('preview-in-new-window', kebabCase(layoutType)), [handleSave, layoutType]
  );
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Button
        disabled={ disabled }
        type="button"
        variant={ variant }
        onClick={ handleClick }
      >
        { text }
      </Button>
    </div>
  );
};

export default PreviewInANewWinBtn;

PreviewInANewWinBtn.propTypes = {
  disabled: PropTypes.bool,
  handleSave: PropTypes.func,
  layoutType: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
};
