/* eslint-disable max-statements */
/* eslint-disable complexity */
// modules
import { Grid } from '@material-ui/core';
import {
  Button,
  ButtonGroup,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  TextField,
  Toggle,
  Divider,
} from '@nike/eds';
import { FormikProvider, useFormik } from 'formik';
import { ContentStatus, ViewMode } from 'lib/enums';
// aliased
import { defaultProps, propTypes } from 'lib/react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import isEmpty from 'lodash/isEmpty';

import {
  CONSENT_FORM,
  DEFAULT_MEDIA,
  SCREENER_FORM,
  SURVEY_FORM,
  WEB_DETAIL_PAGE,
  WEB_OVERVIEW_CARD,
  getAssetType,
  adjustFromUTCtoET,
} from 'lib/layoutUtils';
import {
  convertHandleToTitle,
  getSurveyScheduleRate,
  validationSchema,
} from './lib';
import FormContainer from 'components/FormContainer';
import { isArray, isNil, kebabCase } from 'lodash';
import Fields from './Fields';
import RepeatableFields from './RepeatableFields';
import RepeatableFieldSet from './RepeatableFieldSet';
import ImageFields from './ImageFields';
import {
  AccordionStyled,
  NikeDesignSystemProvider,
} from '@nike/nike-design-system-components';
import { userInitiativePermissions } from 'components/RBAC';
import { getEntitySubtype } from 'lib/auth.js';
import { AutoApprovedSurvey } from './AutoApprovedSurvey';
import PreviewInANewWinBtn from 'views/Initiative/Experiences/components/ManageAssets/components/PreviewNewWinBtn';
import { MaskedTextField } from 'components/fields/MaskedTextField';
import {
  surveyFieldsHelperText,
  surveyPrefix,
  trimAndReplace,
  requiredError,
} from '../utils';
import { INQUIRIES } from './utils';
import TooltipWrapper from 'components/TooltipWrapper';
import { TooltipContainer } from 'components/fields/SelectWithCriteria/styles';
import { getRequiredFields } from 'utils';
import CustomFieldLabel from 'components/fields/CustomFieldLabel';
import DateField from 'components/fields/CustomFieldLabel/DateField';
import { initialValuesTypes } from 'lib/propTypes';
import {
  QuestionSetTypes,
  getFieldValueByFieldName,
} from './RepeatableFieldsetUtils';
import CreatePPI from 'views/PPI/components/Create';
import { PublishModalStyled } from 'views/Initiative/Experiences/components/ManageAssets/components/AssetCard/styled';
import { getPPIgroup } from 'lib/hooks/usePPIlist';
import { KNOWN_INQUIRY_VARIABLES } from 'lib/configs';
import { useFetchConfigQuery } from 'features/adminApi/endpoints/config';
import { GuideModal } from './Components/GuideModal';
import { useExitConfirmation } from 'lib/hooks/useExitConfirmation';

export default applyTo(
  // eslint-disable-next-line complexity
  ({
    submitText = 'Save Media',
    draftExist,
    initialValues,
    handleWebPreview,
    handleSubmit: submit,
    handleSave,
    layoutType,
    viewMode,
    fields,
    setFormState,
    formState,
    initiativeHandle,
    cardStatus,
    isApproved,
    currentData,
    handleIsApproved,
  }) => {
    const history = useHistory();
    const { initiativeType } = useParams();
    const disabled = viewMode === ViewMode.VIEW;
    const createPage = viewMode === ViewMode.CREATE;
    const showConsentDocument = kebabCase(layoutType) === CONSENT_FORM;
    const isInquiry = INQUIRIES.includes(layoutType);
    const experienceUrl = `/initiative/${ initiativeType }`;
    const { surveyId = null } = currentData?.attributes || {};
    const {
      schedule = null,
      handle: surveyHandle = null,
      name: surveyName = null,
    } = currentData || {};

    const [surveyStartDate, setSurveyStartDate] = useState('');
    const [surveyEndDate, setSurveyEndDate] = useState('');
    const [isSurveyScheduled, setIsSurveyScheduled] = useState(false);
    const surveyIdMessage = createPage
      ? surveyFieldsHelperText
      : !surveyId
        ? 'This value will be added to the survey. You can make an initial edit to this value but further changes won\'t be permitted.'
        : surveyFieldsHelperText;

    const showPreviewButton = ![
      DEFAULT_MEDIA,
      SCREENER_FORM,
      SURVEY_FORM,
    ].includes(kebabCase(layoutType));
    const entityType = getEntitySubtype(kebabCase(layoutType));
    const canEdit = userInitiativePermissions(
      initiativeHandle,
      entityType
    ).includes('EDIT');

    const getDateTime = (scheduleDataDateTime) => {
      const adjustedDateTime = adjustFromUTCtoET(scheduleDataDateTime);
      if (!adjustedDateTime) return [null, null];
      return adjustedDateTime.split('T');
    };

    // eslint-disable-next-line
    const _initialValues = {
      ...(layoutType === SURVEY_FORM
        ? {
          surveyId: surveyId ?? undefined,
          surveyName: surveyName ?? undefined,
          surveyHandle: surveyHandle ?? undefined,
          surveyStartDate:
              schedule && schedule.startDate
                ? adjustFromUTCtoET(schedule.startDate)?.split('T')[0]
                : undefined,
          surveyEndDate:
              schedule && getSurveyScheduleRate(schedule.rate) === 'Once'
                ? null
                : schedule && schedule.endDate
                  ? adjustFromUTCtoET(schedule.endDate)?.split('T')[0]
                  : undefined,
          surveyTime:
              schedule && schedule.startDate
                ? schedule.startDate?.split('T')[1]
                : undefined,
          scheduleRate: getSurveyScheduleRate(schedule?.rate) ?? undefined,
          isSurveyScheduled: !!schedule,
          questionSet: isArray(initialValues) ? initialValues : [],
        }
        : {}),
    };

    const getValidationSchema = validationSchema[layoutType];

    const isSurveyStartDateFieldChanged =
      _initialValues.surveyStartDate !== surveyStartDate;

    const isSurveyEndDateFieldChanged =
      _initialValues.surveyEndDate !== surveyEndDate;

    // Utilizing this value to bypass the surveyStartDate field validation since past dates are acceptable and should be
    // allowed if the schedule exists has already started and past the currentDate.Therefore, if a schedule exists, we
    // check only if it's a date or not. Validation will happen after any changes occur to the surveyStartDate field.
    const ignoreStartDateValidation =
      !isSurveyStartDateFieldChanged && _initialValues?.isSurveyScheduled;

    const ignoreEndDateValidation =
      !isSurveyEndDateFieldChanged && _initialValues?.isSurveyScheduled;

    const [inquiryVariables, setInquiryVariables] = useState([]);
    const { data: inquiryVariablesConfigData, isFetching } =
      useFetchConfigQuery(KNOWN_INQUIRY_VARIABLES);

    useEffect(() => {
      if (
        isInquiry &&
        !isFetching &&
        inquiryVariablesConfigData?.value?.inquiryVariables
      ) {
        setInquiryVariables(inquiryVariablesConfigData.value.inquiryVariables);
      }
    }, [isInquiry, isFetching, inquiryVariablesConfigData]);

    useEffect(() => {
      if (isArray(inquiryVariables) && inquiryVariables.length > 0) {
        const inquiryVariableNames = inquiryVariables.map(
          (variable) => variable.name
        );
        sessionStorage.setItem(
          'inquiryVariables',
          JSON.stringify(inquiryVariableNames)
        );
      }
    }, [inquiryVariables]);

    // eslint-disable-next-line
    const _validationSchema =
      layoutType === SURVEY_FORM
        ? getValidationSchema(
          ignoreStartDateValidation,
          ignoreEndDateValidation
        )
        : getValidationSchema;

    const formikData = {
      initialValues:
        layoutType === SURVEY_FORM ? _initialValues : initialValues,
      validationSchema: _validationSchema,
      onSubmit: (values, { setSubmitting }) => {
        submit(values, { setSubmitting });
      },
      displayName: 'AssetForm',
    };

    //Passing in separate formik data for static and dynamic validation
    const formikObject = isInquiry
      ? formikData
      : { ...formikData, enableReinitialize: true };

    const formik = useFormik(formikObject);
    const requiredFields = getRequiredFields(_validationSchema);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    useExitConfirmation(hasUnsavedChanges);

    const {
      values,
      errors,
      dirty,
      isSubmitting,
      touched,
      setFieldError,
      setFieldValue,
      setFieldTouched,
      validateForm,
      handleChange,
      setTouched,
      handleSubmit,
      setValues,
    } = formik;

    const formProps = {
      fields,
      touched,
      setTouched,
      setFieldTouched,
      setFieldError,
      setFieldValue,
      setFormState,
      formState,
      errors,
      disabled,
      initiativeHandle,
      requiredFields,
      initialValues,
      setHasUnsavedChanges,
    };

    const hasErrors = !isEmpty(errors);

    useEffect(() => {
      if (isInquiry & !createPage) setValues({});
    }, [initialValues, isInquiry]);

    useEffect(() => {
      if (
        (values?.logoImage !== '' && values?.logoImage !== undefined) ||
        (values?.backgroundImage !== '' &&
          values?.backgroundImage !== undefined)
      ) {
        validateForm();
      }
    }, [values?.logoImage, values?.backgroundImage]);

    useEffect(() => {
      if (layoutType === SURVEY_FORM) {
        if (createPage && !values?.surveyId) {
          const uuid = touched.surveyId
            ? values?.surveyId
            : window?.crypto?.randomUUID();

          //eslint-disable-next-line
          !isNil(uuid) && setFieldValue('surveyId', uuid);
        } else if (!createPage && isEmpty(touched) && !('surveyId' in values)) {
          setFieldValue('surveyId', surveyId || window?.crypto?.randomUUID());
        }
        if (isSurveyScheduled) {
          if (!('scheduleRate' in values)) {
            setFieldValue('scheduleRate', 'Once');
          }
        }
      }
    }, [schedule, surveyId, values, isSurveyScheduled, touched]);

    useEffect(() => {
      if (layoutType === SURVEY_FORM) {
        if (createPage) {
          const val = touched.surveyHandle
            ? values?.surveyHandle
            : trimAndReplace(values?.surveyName);
          //eslint-disable-next-line
          !isNil(val) && setFieldValue('surveyHandle', val);
        } else if (!createPage && isEmpty(touched)) {
          setFieldValue('surveyHandle', currentData?.handle);
          setFieldValue('surveyName', currentData?.name);
        }
      }
    }, [
      currentData?.name,
      currentData?.handle,
      values?.surveyName,
      values?.surveyHandle,
    ]);

    useEffect(() => {
      if (values) {
        if (values.surveyStartDate) {
          setSurveyStartDate(values.surveyStartDate);
        }
        if (values.surveyEndDate) {
          setSurveyEndDate(values.surveyEndDate);
        }
      }
    }, [values]);

    useEffect(() => {
      if (layoutType === SURVEY_FORM && schedule) {
        const { startDate, endDate, rate } = schedule;
        const [surveyStartDate, surveyTime] = getDateTime(startDate);
        const [surveyEndDate, _] = getDateTime(endDate);
        setIsSurveyScheduled(!!(schedule && schedule !== null));
        setFieldValue('scheduleRate', getSurveyScheduleRate(rate));
        setFieldValue('surveyStartDate', surveyStartDate);
        setFieldValue('surveyEndDate', surveyEndDate);
        setFieldValue('surveyTime', surveyTime);
      }
    }, [layoutType, schedule]);

    useEffect(() => {
      if (layoutType === SURVEY_FORM) {
        setFieldValue('isSurveyScheduled', isSurveyScheduled);
      }
    }, [isSurveyScheduled]);

    //USER HANDLE EVENTS
    const handleCancel = useCallback(() => {
      const assetUrl = `${ experienceUrl }/${ initiativeHandle }/assets`;
      history.push({
        pathname: assetUrl,
        state: {
          assetType: getAssetType(layoutType),
        },
      });
    }, [initiativeHandle, layoutType]);

    const handleEditRoute = useCallback(() => {
      const editUrl = `${ experienceUrl }/${ initiativeHandle }/assets/edit/${ kebabCase(
        layoutType
      ) }`;
      const layoutId = history?.location?.state?.layoutId;
      const draftExist = history?.location?.state?.draftExist;
      history.push({
        pathname: editUrl,
        state: {
          layoutId,
          disabled: true,
          draftExist,
        },
      });
    }, [initiativeHandle]);

    const handleChangeCustom = useCallback((event) => {
      const key = event?.target?.id;
      const value = event?.target?.value;
      setFieldValue(key, value);
      setFieldTouched(key, true);
      setFormState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
      // Invoking the original handleChange func from Formik
      handleChange(event);
    }, []);

    const handleFormSubmit = useCallback((event) => {
      event.preventDefault();
      setHasUnsavedChanges(false);
      handleSubmit();
    }, []);

    const handleWebPreviewSubmit = useCallback((previewInNewWindow, entityType) => {
      handleWebPreview(previewInNewWindow, entityType, values);
    }, [handleWebPreview, values]);

    const handleMaskedTextChange = useCallback((ev) => {
      const key = ev?.target?.id;
      const val = ev?.target?.value.trim();
      setFieldValue(key, trimAndReplace(val));
      setFieldTouched(key, true);
    }, []);

    const handleSurveyIdChange = useCallback((event) => {
      const key = event?.target?.id;
      const value = event?.target?.value;
      setFieldValue(key, value);
      setFieldTouched(key, true);
      // Invoking the original handleChange func from Formik
      handleChange(event);
    }, []);

    const handleChangeName = useCallback((event) => {
      const key = event?.target?.id;
      const value = event?.target?.value;
      setFieldValue(key, value);
      setFieldTouched(key, true);
      handleChange(event);
      setHasUnsavedChanges(true);
    }, []);

    const handleFieldChange = useCallback(
      (fieldName) => (event) => {
        setFieldValue(fieldName, event.target.value);
        setFieldTouched(fieldName, true);
        setHasUnsavedChanges(true);
      },
      []
    );

    const onToggleScheduleSurvey = useCallback(() => {
      setIsSurveyScheduled((prevState) => !prevState);
      setFieldTouched('isSurveyScheduled', true);
      setHasUnsavedChanges(true);
    }, []);

    const surveyQuestionCheck =
      layoutType === SURVEY_FORM
        ? createPage
          ? isEmpty(values?.questionSet)
          : !('surveyId' in values) && isEmpty(touched)
        : false;

    const checkConditionalOptions =
      !isEmpty(formState) &&
      isArray(formState) &&
      formState?.filter(
        (questionSet, index) =>
          index > 0 &&
          getFieldValueByFieldName(
            questionSet?.value,
            QuestionSetTypes.CONDITIONAL_TOGGLE
          ) &&
          getFieldValueByFieldName(
            questionSet?.value,
            QuestionSetTypes.CONDITIONAL_OPTIONS
          ) === undefined
      );

    const ignoringValidatedFields =
      Object.keys(values).length === (surveyId ? 3 : 2) &&
      'surveyHandle' in values &&
      'surveyName' in values &&
      !createPage &&
      !touched?.surveyName &&
      (!surveyId || 'surveyId' in values);

    useEffect(() => {
      const checkAndRemoveError = (fieldName, value) => {
        if (value !== undefined && value !== null && value.trim() !== '') {
          setFieldError(fieldName, undefined);
        }
      };
      if ('surveyId' in values) {
        checkAndRemoveError('surveyId', values.surveyId);
      }
      if (errors?.surveyHandle === requiredError && 'surveyHandle' in values) {
        checkAndRemoveError('surveyHandle', values.surveyHandle);
      }
      if (errors?.surveyName === requiredError && 'surveyName' in values) {
        checkAndRemoveError('surveyName', values.surveyName);
      }
    }, [
      errors?.surveyHandle,
      errors?.surveyName,
      values.surveyId,
      values.surveyHandle,
      values.surveyName,
    ]);

    const checkNewQuestionHasValues = useMemo(() => {
      const questionSet = isArray(formState)
        ? formState[formState?.length - 1]?.value
        : [];
      return isArray(formState)
        ? getFieldValueByFieldName(
          questionSet,
          QuestionSetTypes.CATEGORY_CHOICE
        )
          ? true
          : !!(
            getFieldValueByFieldName(
              questionSet,
              QuestionSetTypes.QUESTION_CATEGORY
            ) &&
              getFieldValueByFieldName(
                questionSet,
                QuestionSetTypes.QUESTION_LABEL
              ) &&
              getFieldValueByFieldName(
                questionSet,
                QuestionSetTypes.EXISTING_PPI
              )
          )
        : false;
    }, [formState]);

    const formButtonDisabled = useMemo(() => {
      return (
        isEmpty(values) ||
        ignoringValidatedFields ||
        (formState?.length > 1 && !isEmpty(checkConditionalOptions)) ||
        (hasErrors && !touched?.surveyId
          ? !(Object.keys(errors).length === 1 && 'surveyId' in errors)
          : hasErrors) ||
        (isInquiry ? isEmpty(touched) : !dirty && isEmpty(touched)) ||
        (isInquiry ? (checkNewQuestionHasValues ? hasErrors : true) : false) ||
        (isSurveyScheduled && !values?.surveyStartDate) ||
        (showConsentDocument
          ? formState?.sections?.updatedFields?.slice(-1)[0]?.value === ''
          : false) ||
        surveyQuestionCheck
      );
    }, [
      values,
      ignoringValidatedFields,
      formState,
      checkConditionalOptions,
      errors,
      touched,
      dirty,
      surveyQuestionCheck,
      isSurveyScheduled,
      checkNewQuestionHasValues,
      isInquiry,
    ]);

    const [modalOpen, setModalOpen] = useState(false);
    const [newPPI, setNewPPI] = useState({});

    useEffect(() => {
      if (isArray(values?.questionSet) && values?.questionSet.length > 0) {
        values?.questionSet?.forEach((questionSet) => {
          const existingPPI = getFieldValueByFieldName(
            questionSet?.value,
            QuestionSetTypes.EXISTING_PPI
          );
          if (
            existingPPI?.value === 'New PPI' &&
            INQUIRIES.includes(layoutType)
          ) {
            setModalOpen(true);
          }
        });
      }
    }, [values, layoutType]);

    const dismissModal = useCallback(() => {
      setModalOpen(!modalOpen);
    }, [modalOpen]);

    const createPPICallback = useCallback(
      (values) => {
        dismissModal();
        const newPPIOption = {
          label: convertHandleToTitle(values.name),
          value: values.handle,
          group: getPPIgroup(values.handle),
        };
        setNewPPI(newPPIOption);
      },
      [dismissModal, setNewPPI]
    );

    return (
      <FormikProvider value={ formik }>
        <NikeDesignSystemProvider>
          { INQUIRIES.includes(layoutType) && (
            <div className={ styles.createPPIModal }>
              <PublishModalStyled
                isOpen={ modalOpen }
                onDismiss={ dismissModal }
                hideFade={ true }
              >
                <CreatePPI onSubmit={ createPPICallback } />
              </PublishModalStyled>
            </div>
          ) }
          <FormContainer>
            <form
              onSubmit={ handleFormSubmit }
              encType="multipart/form-data"
              className={ styles.form }
              data-testid="add-edit-form-view"
            >
              <Grid
                container={ true }
                direction="column"
                spacing={ 2 }
                justifyContent="space-evenly"
              >
                { layoutType === SURVEY_FORM && (
                  <Grid
                    container={ true }
                    direction="row"
                    spacing={ 3 }
                    style={{ marginTop: '25px' }}
                  >
                    <Grid item={ true } xs={ 12 }>
                      <TextField
                        id="surveyName"
                        name="surveyName"
                        label={
                          <label>
                            Survey Name
                            { requiredFields.surveyName &&
                              !(
                                !!errors?.surveyName && touched?.surveyName
                              ) && <span className="asterisk">*</span> }
                          </label>
                        }
                        onChange={ handleChangeName }
                        disabled={ disabled }
                        value={
                          touched?.surveyName
                            ? values?.surveyName || ''
                            : currentData?.name || ''
                        }
                        hasErrors={ !!errors?.surveyName && touched?.surveyName }
                        errorMessage={ hasErrors && errors?.surveyName }
                        message={ <GuideModal /> }
                      />
                    </Grid>
                    <Grid item={ true } xs={ 12 }>
                      <MaskedTextField
                        id="surveyHandle"
                        name="surveyHandle"
                        label="Survey Handle"
                        onChange={ handleMaskedTextChange }
                        handleType={ surveyPrefix }
                        createPage={ createPage }
                        helperText={ surveyFieldsHelperText }
                        value={
                          !createPage
                            ? values?.surveyHandle || currentData?.handle
                            : values?.surveyHandle || touched.surveyHandle
                              ? values?.surveyHandle
                              : trimAndReplace(values?.surveyName)
                        }
                        disabled={ !createPage || disabled }
                        className={ !createPage ? styles.greyOut : '' }
                        required={ requiredFields.surveyHandle }
                      />
                    </Grid>
                    <Grid item={ true } xs={ 12 }>
                      <TooltipContainer>
                        <TooltipWrapper
                          active={ !!(!createPage && surveyId) || disabled }
                          message={
                            <Text
                              font="subtitle-2"
                              className="eds-spacing--mb-4"
                              style={{ wordBreak: 'break-word' }}
                            >
                              {
                                '⚠️ Once survey is published, surveyId cannot be edited again.'
                              }
                            </Text>
                          }
                          children={
                            <TextField
                              id="surveyId"
                              name="surveyId"
                              label={
                                <label>
                                  Survey Id
                                  { requiredFields.surveyId &&
                                    !(
                                      !!errors?.surveyId && touched?.surveyId
                                    ) && <span className="asterisk">*</span> }
                                </label>
                              }
                              message={ surveyIdMessage }
                              hasErrors={
                                !!errors?.surveyId && touched?.surveyId
                              }
                              errorMessage={
                                hasErrors &&
                                touched?.surveyId &&
                                !!errors?.surveyId &&
                                errors?.surveyId
                              }
                              disabled={ (!createPage && surveyId) || disabled }
                              className={
                                !createPage &&
                                values?.surveyId &&
                                !errors.surveyId
                                  ? styles.greyOut
                                  : ''
                              }
                              onChange={ handleSurveyIdChange }
                              value={
                                !createPage
                                  ? values?.surveyId || surveyId || ''
                                  : values?.surveyId || touched.surveyId
                                    ? values.surveyId
                                    : ''
                              }
                            />
                          }
                        />
                      </TooltipContainer>
                    </Grid>
                    { isSurveyScheduled && (
                      <Grid item={ true } xs={ 12 }>
                        <Divider />
                      </Grid>
                    ) }
                    <Grid item={ true } xs={ 12 }>
                      <Toggle
                        id="scheduleSurvey"
                        label={
                          <CustomFieldLabel
                            label="Schedule survey"
                            font="title-6"
                          />
                        }
                        onChange={ onToggleScheduleSurvey }
                        checked={ isSurveyScheduled }
                      />
                    </Grid>
                    { isSurveyScheduled && (
                      <>
                        <Grid item={ true } xs={ 12 }>
                          <RadioGroup
                            id="scheduleRate"
                            name="scheduleRateRadioBtnGroup"
                            orientation="horizontal"
                            valueSelected={ values?.scheduleRate ?? 'Once' }
                            onChange={ handleFieldChange('scheduleRate') }
                          >
                            <Radio
                              label={ <CustomFieldLabel label="Once" /> }
                              value="Once"
                              id="Once"
                            />
                            <Radio
                              label={ <CustomFieldLabel label="Daily" /> }
                              value="Daily"
                              id="Daily"
                            />
                            <Radio
                              label={ <CustomFieldLabel label="Weekly" /> }
                              value="Weekly"
                              id="Weekly"
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item={ true } xs={ 12 } container={ true } spacing={ 2 }>
                          <Grid
                            item={ true }
                            xs={ values?.scheduleRate !== 'Once' ? 6 : 12 }
                          >
                            <DateField
                              fieldName="surveyStartDate"
                              id="survey-start-date"
                              name="surveyStartDate"
                              label={
                                values?.scheduleRate === 'Once'
                                  ? 'Survey Send Date'
                                  : 'Survey Start Date'
                              }
                              setHasUnsavedChanges={ setHasUnsavedChanges }
                            />
                          </Grid>
                          { values?.scheduleRate !== 'Once' && (
                            <Grid item={ true } xs={ 6 }>
                              <DateField
                                fieldName="surveyEndDate"
                                label="Survey End Date"
                                setHasUnsavedChanges={ setHasUnsavedChanges }
                              />
                            </Grid>
                          ) }
                          <Grid item={ true } xs={ 12 }>
                            <DateField
                              type="time"
                              fieldName="surveyTime"
                              label="Survey Send Time"
                              message="Warning: Surveys are scheduled based on the east coast timezone"
                              setHasUnsavedChanges={ setHasUnsavedChanges }
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) }
                  </Grid>
                ) }
                <Grid container={ true } direction="row" spacing={ 3 }>
                  <fieldset
                    disabled={ !!disabled }
                    data-testid="asset-field-set"
                    id="asset-form"
                    style={{ width: '100%' }}
                  >
                    <input
                      type="hidden"
                      name="layoutId"
                      value={ values?.layoutId }
                    />
                    <Grid
                      container={ true }
                      direction="row"
                      spacing={ 0 }
                      className={ styles.gridTitle }
                    >
                      <Grid item={ true } xs={ 12 }>
                        <Text font="title-6" as="h6">
                          { convertHandleToTitle(values?.contentType) }
                        </Text>
                      </Grid>
                    </Grid>
                    { layoutType === SCREENER_FORM ? (
                      <>
                        <AutoApprovedSurvey
                          isApproved={ isApproved }
                          handleIsApproved={ handleIsApproved }
                          isCreatePage={ createPage }
                        />
                        <AccordionStyled
                          id="screenerQuestionForm"
                          key="screenerQuestionForm"
                          open={ true }
                          size={ 'large' }
                        >
                          <RepeatableFieldSet
                            layoutType={ layoutType }
                            newPPI={ newPPI }
                            isApproved={ isApproved }
                            { ...formProps }
                          />
                        </AccordionStyled>
                      </>
                    ) : layoutType === SURVEY_FORM ? (
                      <AccordionStyled
                        id="surveyQuestionForm"
                        key="surveyQuestionForm"
                        open={ true }
                        size={ 'large' }
                      >
                        <RepeatableFieldSet
                          layoutType={ layoutType }
                          newPPI={ newPPI }
                          isApproved={ isApproved }
                          { ...formProps }
                        />
                      </AccordionStyled>
                    ) : (
                      !showConsentDocument && (
                        <Fields
                          { ...formProps }
                          layoutType={ layoutType }
                          handleChange={ handleChangeCustom }
                        />
                      )
                    ) }
                    { showConsentDocument && (
                      <RepeatableFields
                        layoutType={ layoutType }
                        { ...formProps }
                      />
                    ) }
                    <ImageFields
                      { ...formProps }
                      viewMode={ viewMode }
                      requiredFields={ requiredFields }
                    />
                  </fieldset>
                </Grid>
                <Grid container={ true } direction="row" spacing={ 3 }>
                  <ButtonGroup className={ styles.btns }>
                    <Button
                      variant="secondary"
                      onClick={ handleCancel }
                      type="button"
                    >
                      Cancel
                    </Button>
                    { !disabled && typeof handleWebPreview === 'function' && (
                      <PreviewInANewWinBtn
                        disabled={ formButtonDisabled }
                        handleSave={ handleWebPreviewSubmit }
                        layoutType={ layoutType }
                        text="Preview on the web"
                      />
                    ) }
                    { !disabled ? (
                      <ButtonGroup>
                        { showPreviewButton &&
                          [WEB_DETAIL_PAGE, WEB_OVERVIEW_CARD].includes(
                            kebabCase(layoutType)
                          ) && (
                          <PreviewInANewWinBtn
                            disabled={ hasErrors || isSubmitting }
                            handleSave={ handleSave }
                            layoutType={ layoutType }
                          />
                        ) }
                        { showPreviewButton && (
                          <Button
                            disabled={ hasErrors || isSubmitting || !dirty }
                            type="button"
                            variant="primary"
                            onClick={ handleSave }
                          >
                            Preview
                          </Button>
                        ) }
                        <Button
                          data-testid="submit-button"
                          variant="primary"
                          disabled={ formButtonDisabled }
                          type="submit"
                        >
                          { isSubmitting ? (
                            <div className="eds--dark">
                              <Spinner />
                            </div>
                          ) : (
                            submitText
                          ) }
                        </Button>
                      </ButtonGroup>
                    ) : (
                      !draftExist &&
                      canEdit && (
                        <ButtonGroup>
                          { [WEB_DETAIL_PAGE, WEB_OVERVIEW_CARD].includes(
                            kebabCase(layoutType)
                          ) && (
                            <PreviewInANewWinBtn
                              disabled={ draftExist }
                              handleSave={ handleSave }
                              layoutType={ layoutType }
                            />
                          ) }
                          { /* Remove edit on Previous version consents and
                             show only on Published when no draft exist */ }
                          { cardStatus === ContentStatus.PUBLISHED && (
                            <Button
                              disabled={ draftExist }
                              data-testid="editRoute"
                              variant="outlined"
                              onClick={ handleEditRoute }
                            >
                              Edit
                            </Button>
                          ) }
                        </ButtonGroup>
                      )
                    ) }
                  </ButtonGroup>
                </Grid>
              </Grid>
            </form>
          </FormContainer>
        </NikeDesignSystemProvider>
      </FormikProvider>
    );
  },
  pipe(
    propTypes({
      cardStatus: PropTypes.oneOf(Object.values(ContentStatus)),
      draftExist: PropTypes.bool,
      handleReset: PropTypes.func,
      handleSubmit: PropTypes.func,
      handleWebPreview: PropTypes.func,
      initialValues: PropTypes.oneOfType([
        initialValuesTypes.initialInquiryData,
        initialValuesTypes.initialConsentData,
        initialValuesTypes.initialContentData,
      ]),
      formFields: PropTypes.shape({}),
      onSubmit: PropTypes.func,
      layoutType: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    defaultProps({
      submitText: 'Save Media',
      handleReset: () => {},
      handleSubmit: () => {},
      handleWebPreview: null,
      onSubmit: () => {},
      setFormState: () => {},
      initialValues: {},
      formFields: {},
      layoutType: 'default',
      fields: [{}],
    })
  )
);
