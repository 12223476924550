// modules
import React, { memo, useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { applyTo, pipe, isEmpty } from 'ramda';
import { useFormikContext, withFormik } from 'formik';
import { TextField } from 'components/fields';
import { useHistory } from 'react-router-dom';

// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import {
  INITIAL_CONFIG_FIELDS_VALUES,
  INITIAL_VALUES,
  validationSchema,
  validationSchemaConfigFields,
} from './lib';
import styles from './index.module.scss';
import { ViewMode } from 'lib/enums';
import { MaskedTextField } from 'components/fields/MaskedTextField';
import { trimAndReplace } from '../utils';
import { isNil } from 'lodash';
import { Button, Spinner, Text } from '@nike/eds';
import { getRequiredFields } from 'utils';
import JsonEditorComponent from 'components/fields/JSONEditorField';

export default applyTo(
  //eslint-disable-next-line
  ({
    handleSubmit,
    errors,
    dirty,
    values,
    viewMode,
    setFieldValue,
    isConfigFieldType,
    touched,
  }) => {
    const history = useHistory();
    const disabled = viewMode === ViewMode.READ;
    const createPage = viewMode === ViewMode.CREATE && true;
    const editUrl = `edit/${ values?.handle }`;
    const { isSubmitting } = useFormikContext();

    useEffect(() => {
      const val = trimAndReplace(values?.name);
      if (!isNil(val) && createPage) setFieldValue('handle', val);
    }, [values?.name]);

    //USER HANDLE EVENTS
    const handleCancel = useCallback((event) => {
      event.preventDefault();
      history.goBack();
    }, []);

    const handleEditRoute = useCallback(() => {
      history.push(editUrl);
    }, []);

    const handleChange = useCallback((ev) => {
      const val = ev.target?.value;
      return setFieldValue('handle', trimAndReplace(val));
    }, []);

    const requiredFields = getRequiredFields(
      isConfigFieldType ? validationSchemaConfigFields : validationSchema
    );

    return (
      <form onSubmit={ handleSubmit }>
        <fieldset disabled={ !!disabled } data-testid="fieldSet">
          <>
            <Grid
              container={ true }
              direction="row"
              spacing={ 0 }
              className={ styles.fieldSetGrid }
            >
              <Grid
                container={ true }
                direction="column"
                item={ true }
                xs={ 12 }
                spacing={ 4 }
              >
                <Grid item={ true }>
                  <Text font="title-6" as="h6">
                    Properties
                  </Text>
                </Grid>
              </Grid>
            </Grid>
            <Grid container={ true } direction="row" spacing={ 3 }>
              <Grid item={ true } xs={ 12 }>
                { isConfigFieldType ? (
                  <TextField name="description" label="Name" />
                ) : (
                  <TextField
                    name="name"
                    label="Name"
                    required={ requiredFields.name }
                  />
                ) }
              </Grid>
              <Grid item={ true } xs={ 12 }>
                <MaskedTextField
                  name="handle"
                  label="Handle"
                  onChange={ handleChange }
                  handleType={ isConfigFieldType ? 'fld' : 'config' }
                  createPage={ createPage }
                  value={
                    !createPage
                      ? values?.handle
                      : values?.handle || touched?.handle
                        ? values?.handle
                        : trimAndReplace(values?.handle)
                  }
                  disabled={ (!createPage && true) || disabled }
                  className={ !createPage ? styles.greyOut : '' }
                  required={ requiredFields.name }
                />
              </Grid>
              <Grid item={ true } xs={ 12 }>
                <JsonEditorComponent
                  name="value"
                  label="JSON Value"
                  value={ values?.value }
                  errorMessage={ errors?.value }
                />
              </Grid>
            </Grid>
          </>
        </fieldset>
        <div className={ styles.btns }>
          <Button variant="secondary" onClick={ handleCancel }>
            Cancel
          </Button>
          { !disabled ? (
            <Button
              variant="primary"
              disabled={
                isSubmitting || !isEmpty(errors) || !dirty || isEmpty(touched)
              }
              type="submit"
            >
              { isSubmitting ? <Spinner /> : 'Submit' }
            </Button>
          ) : (
            <Button
              data-testid="editRoute"
              variant="secondary"
              onClick={ handleEditRoute }
            >
              Edit
            </Button>
          ) }
        </div>
      </form>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues, isConfigFieldType }) =>
        (isEmpty(initialValues) || !initialValues
          ? isConfigFieldType
            ? INITIAL_CONFIG_FIELDS_VALUES
            : INITIAL_VALUES
          : initialValues),
      validationSchema: ({ isConfigFieldType }) =>
        (isConfigFieldType ? validationSchemaConfigFields : validationSchema),
      handleSubmit: (
        values,
        { setSubmitting, props: { onSubmit, viewMode, isConfigFieldType } }
      ) => {
        const { handle, name = '', value, description = '' } = values;
        const request = isConfigFieldType
          ? {
            handle,
            value,
            description,
          }
          : { handle, name, value };
        const fieldHandle =
          (viewMode === ViewMode.CREATE && `fld-${ handle }`) || handle;
        const modifiedRequest = { ...request, handle: fieldHandle };
        onSubmit(modifiedRequest);
      },
      displayName: ({ isConfigFieldType }) =>
        (!isConfigFieldType ? 'ConfigForm' : 'Config Field Form'),
    }),
    propTypes({
      id: PropTypes.string,
      handleReset: PropTypes.func,
      handleSubmit: PropTypes.func,
      initialValues: PropTypes.shape({}),
      onSubmit: PropTypes.func,
      statusConditions: PropTypes.array,
      isConfigFieldType: PropTypes.bool,
    }),
    defaultProps({
      handleReset: () => {},
      handleSubmit: () => {},
      requiredPPI: [],
      onSubmit: () => {},
    }),
    memo
  )
);
