/* eslint-disable max-statements */
import React, { useEffect, useMemo } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Downshift from 'downshift';
import FieldWrapper from 'components/FieldWrapper';
import styles from './index.module.scss';
import { errorMessage as helperNoteMessage } from '../AnswerOptionField/utils';
import { arrayOfObjects } from 'lib/propTypes';
import { QuestionSetTypes } from 'components/forms/Assets/RepeatableFieldsetUtils';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  main: {
    '& >div': {
      backgroundColor: 'white',
      border: 0,
      display: 'revert',
    },
    '& > div.Mui-focused fieldset': {
      borderColor: '#111111',
      borderWidth: '1px !important',
    },
  },
  label: {
    fontSize: 18,
    fontWeight: 500,
    position: 'absolute',
    top: 0,
  },
}));

export default function TagsInput({ ...props }) {
  const [{ value, ...field }, meta, helpers] = useField(props);
  const classes = useStyles();
  const {
    id,
    onChange,
    placeholder,
    tags,
    title,
    helperText,
    onTagAdd,
    separators,
    errorMessage,
    setFieldValue,
    required,
    ...other
  } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState([]);
  const { setValue } = helpers;

  useEffect(() => {
    if (
      selectedItem.length > 0 &&
      JSON.stringify(selectedItem) !== JSON.stringify(value)
    ) {
      setValue(selectedItem);
    }
  }, [selectedItem]);

  const getTagValue = (item) => {
    return typeof item === 'string' ? item : item?.value;
  };

  const selectedItemVal = useMemo(() => {
    return tags?.map((item) => item);
  }, [tags]);

  useEffect(() => {
    setSelectedItem(selectedItemVal);
  }, [selectedItemVal]);

  useEffect(() => {
    if (onTagAdd) {
      const newSelectedItem = [...selectedItem];
      const newItems = newSelectedItem
        .map((item) => (onTagAdd.includes(getTagValue(item)) ? null : item))
        .filter((item) => item !== null);
      setSelectedItem(newItems);
    }
  }, [onTagAdd]);

  function handleKeyDown(event) {
    if (separators.includes(event.key)) {
      const { setTouched } = helpers;
      event.preventDefault();
      const newSelectedItem = [...selectedItem];
      const duplicatedValues = newSelectedItem.some(
        (item) => getTagValue(item) === event.target.value.trim()
      );

      if (duplicatedValues) {
        setInputValue('');
        return;
      }
      if (!event.target.value.replace(/\s/g, '').length) return;

      const splitSelectedItem = separators.includes(' ')
        ? event.target.value.split(/\.|\n|\s/)
        : event.target.value.split(/\.|\n/);

      if (id.includes(QuestionSetTypes.ANSWER_OPTIONS)) {
        const newItems = splitSelectedItem.map((tag) => ({
          name: tag,
          value: tag,
          selected: false,
          attributes: { description: null },
          sortOrder: newSelectedItem.length,
        }));
        newSelectedItem.push(...newItems);
        setSelectedItem(newSelectedItem);
      } else {
        newSelectedItem.push(...splitSelectedItem);
        const cleanSelectedItem = newSelectedItem.map((value) =>
          value.replaceAll(',', '')
        );
        setSelectedItem(cleanSelectedItem);
      }
      onChange({ target: { name: field.name } }, newSelectedItem);
      setInputValue('');
      setTouched(true);
    }
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === 'Backspace'
    ) {
      const newSelectedItem = selectedItem.slice(0, selectedItem.length - 1);
      setFieldValue(id, newSelectedItem);
      setSelectedItem(newSelectedItem);
      onChange({ target: { name: field.name } }, newSelectedItem);
    }
  }

  function handleChange(item) {
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue('');
    setFieldValue(id, newSelectedItem);
    setSelectedItem(newSelectedItem);
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    onChange({ target: { name: field.name } }, newSelectedItem);
    setFieldValue(id, newSelectedItem);
    setSelectedItem(newSelectedItem);
  };

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  const showError = meta?.value?.length > 0 && meta.error;
  const errorValue = 'One or more ids are not in correct format.'; //meta.error //need to only show this once
  const helperTextCombined = (
    <span className={ styles.helperTextContainer }>
      <span>{ errorMessage || (showError && errorValue) }</span>
      <span>{ helperText }</span>
    </span>
  );

  return (
    <FieldWrapper disabled={ props.disabled }>
      <>
        <label className={ classes.label }>
          { title }
          { required && (
            <span className={ `asterisk ${ showError ? 'hasErrors' : '' }` }>
              *
            </span>
          ) }
        </label>
        <Downshift
          id="downshift-multiple"
          inputValue={ inputValue }
          // eslint-disable-next-line react/jsx-no-bind
          onChange={ handleChange }
          selectedItem={ selectedItem }
        >
          { ({ getInputProps }) => {
            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
              onKeyDown: handleKeyDown,
              placeholder,
            });
            return (
              <div>
                <FieldWrapper disabled={ props.disabled }>
                  <TextField
                    error={
                      (!helperText.includes(helperNoteMessage) &&
                        !!(meta.touched && errorMessage)) ||
                      !!(meta.touched && showError)
                    }
                    value={ selectedItem
                      .map((item) => getTagValue(item))
                      .join(', ') }
                    className={ classes.main }
                    helperText={ helperTextCombined }
                    multiline={ true }
                    minRows="3"
                    InputProps={{
                      startAdornment: selectedItem.map((item) => (
                        <Chip
                          id={ `chip-${ getTagValue(item) }` }
                          key={ getTagValue(item) }
                          tabIndex={ -1 }
                          label={ getTagValue(item) }
                          variant="outlined"
                          size="small"
                          className={ classes.chip }
                          onDelete={ handleDelete(item) }
                        />
                      )),
                      onBlur,
                      onChange: (event) => {
                        handleInputChange(event);
                        onChange(event);
                      },
                      onFocus,
                    }}
                    { ...field }
                    { ...other }
                    { ...inputProps }
                  />
                </FieldWrapper>
              </div>
            );
          } }
        </Downshift>
      </>
    </FieldWrapper>
  );
}
TagsInput.defaultProps = {
  helperText: 'Type or paste options separated by a space, comma or line break',
  onChange() {},
  separators: [' ', ',', 'Enter', 'Tab'],
  tags: [],
};
TagsInput.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onTagAdd: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  selectedTags: PropTypes.func,
  separators: PropTypes.arrayOf(PropTypes.string),
  setFieldValue: PropTypes.func,
  tags: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    arrayOfObjects,
  ]),
  title: PropTypes.string,
};
