import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

const useSyncAndValidateForm = (
  trigger,
  setTrigger,
  repeatableFieldSet,
  setHasUnsavedChanges
) => {
  const [isFormikValueUpdated, setIsFormikValueUpdated] = useState(false);
  const { validateForm, values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (trigger) {
      setFieldValue('questionSet', repeatableFieldSet);
      setIsFormikValueUpdated(true);
      setHasUnsavedChanges(true);
      setTrigger(false);
    }
  }, [trigger, repeatableFieldSet]);

  useEffect(() => {
    if (isFormikValueUpdated) {
      const timer = setTimeout(() => {
        validateForm(values)
          .then(() => {
            setIsFormikValueUpdated(false);
          })
          .catch((error) => {
            console.error('Validation error:', error);
          });
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [isFormikValueUpdated, values]);
};

export default useSyncAndValidateForm;
